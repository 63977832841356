import React, { useEffect, useState } from 'react';

const API_KEY = 'AIzaSyB-lc0XvLEzTeyB1vv7yAi1or_HaUixejc';
const CHANNEL_ID = 'UCvbGbhVuU1VWOzMOPf79mOw';

const Resources = () => {
  const [playlists, setPlaylists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPlaylists = async () => {
      try {
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/playlists?part=snippet&channelId=${CHANNEL_ID}&maxResults=50&key=${API_KEY}`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch playlists');
        }
        const data = await response.json();
        setPlaylists(data.items);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPlaylists();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="m-3">
      <div className="content">
        <h1 className="title">Video Resources</h1>
        <p>
          We have curated the following video playlists for use by both our
          students and anyone who visits this site. Each playlist covers various topics within the subject.
        </p>
      </div>
      <br />
      {playlists.map((playlist) => (
        <div key={playlist.id} className="mb-6">
          <h3 className="title">{playlist.snippet.title}</h3>
          <div className="video-container">
            <iframe
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/videoseries?list=${playlist.id}`}
              title={playlist.snippet.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      ))}
      <div className="mt-6 has-text-centered">
        <p className="is-size-5">More videos are coming soon!</p>
        <p className="is-size-5">
          Don't miss out - <a href={`https://www.youtube.com/channel/${CHANNEL_ID}`} target="_blank" rel="noopener noreferrer">subscribe to our YouTube channel</a> for the latest updates.
        </p>
      </div>
    </div>
  );
};

export default Resources;